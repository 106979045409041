import { useState, useEffect } from 'react';
import { getBanners } from 'services/banners';
import BannerSkeleton from 'common/components/Skeletons/BannerSkeleton';
import Banner from 'common/components/Home/Banners/Banner';

const Banners = () => {
  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleBanners = async () => {
    setIsLoading(true);
    let data = await getBanners();
    setBanners(data.sort((a, b) => (a.fields.position > b.fields.position ? 1 : -1)));
    setIsLoading(false);
  };

  useEffect(() => {
    handleBanners();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <div className="banner__container">
            <BannerSkeleton />
          </div>
          <div className="banner__container">
            <BannerSkeleton />
          </div>
          <div className="banner__container">
            <BannerSkeleton />
          </div>
          <div className="banner__container">
            <BannerSkeleton />
          </div>
        </>
      ) : (
        banners && banners.map(banner => <Banner banner={banner} key={banner.sys.id} />)
      )}
    </>
  );
};

export default Banners;
