import { useState } from 'react';
import Prompt from 'common/components/Prompt/Prompt';
import Message from 'common/components/Layout/Message';

const Banner = ({ banner }) => {
  const [prompt, setPrompt] = useState(false);

  const togglePrompt = () => {
    setPrompt(!prompt);
  };

  const {
    fields: { external, backgroundImage, icon, textColor, title, description, url },
  } = banner;
  const handleLink = () => {
    return external ? (
      <>
        <div className="banner__container" onClick={togglePrompt}>
          {backgroundImage && (
            <img className="banner__background" src={backgroundImage.fields.file.url} />
          )}
          <div className="banner__card rounded">
            <div className="widget-card-cover rounded" />
            <div className="widget-card-content pb-0">
              <div className={`banner__icon mb-5px ${backgroundImage ? 'invisible' : ''}`}>
                <i className={`${icon} fa-1x text-white`} />
              </div>
              <h4
                className={`banner__title ellipsis-sl ${
                  textColor === 'White' ? 'banner__title--white' : ''
                }`}
              >
                {title}
              </h4>
            </div>
            <div className="widget-card-content bottom pt-0">
              <p
                className={`banner__paragraph ellipsis-sl ${
                  textColor === 'White' ? 'banner__title--white' : ''
                }`}
                data-id="widget-elm"
                data-light-class="fs-12px text-black-transparent-7"
                data-dark-class="fs-12px text-white-transparent-7"
              >
                {description}
              </p>
            </div>
          </div>
        </div>
        {prompt && (
          <Prompt>
            <Message url={url} handleClose={togglePrompt} />
          </Prompt>
        )}
      </>
    ) : (
      <div key={banner.sys.id} className="banner__container">
        {backgroundImage && (
          <img className="banner__background" src={backgroundImage.fields.file.url} />
        )}
        <a href={url} className="banner__card rounded" data-id="widget" target="_blank">
          <div className="widget-card-cover rounded" />
          <div className="widget-card-content pb-0">
            <div className={`banner__icon mb-5px ${backgroundImage ? 'invisible' : ''}`}>
              <i className={`${icon} fa-1x text-white`} />
            </div>
            <h4
              className={`banner__title ellipsis-sl ${
                textColor === 'White' ? 'banner__title--white' : ''
              }`}
            >
              {title}
            </h4>
          </div>
          <div className="widget-card-content bottom pt-0">
            <p
              className={`banner__paragraph ellipsis-sl ${
                textColor === 'White' ? 'banner__title--white' : ''
              }`}
              data-id="widget-elm"
              data-light-class="fs-12px text-black-transparent-7"
              data-dark-class="fs-12px text-white-transparent-7"
            >
              {description}
            </p>
          </div>
        </a>
      </div>
    );
  };

  return <>{handleLink()}</>;
};

export default Banner;
