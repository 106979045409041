import { useEffect, useState, Suspense } from 'react';
import { useStore } from 'context/Store';

// ROUTES CONFIG
import AuthRoute from 'common/auth/authRoute';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

// METHODS
import { getToken } from 'common/utils/localStorage';
import { getUserId } from 'common/utils/localStorage';
import {
  getSpecialtyCategories,
  getSpecialties,
  getIndustries,
  getCertifications,
  getVLPServices,
} from 'services/firm';
import { getCountries, getCities, getRegions } from 'services/regions';
import { getNetworkRoles, getFirmRoles } from 'services/roles';
import { getUserById } from 'services/user';
import { getFirm } from 'services/firm';
import { hasPermission } from 'common/utils/auth';
import { parseJwt } from 'common/utils/jwt';
import { initAnalytics } from 'common/utils/googleAnalytics';

// CONSTANTS
import { FIRM_ADMIN_PERMISSIONS, ADMIN_PERMISSIONS } from 'common/permissions';

// LAYOUT COMPONENTS
import Footer from 'common/components/Footer/Footer';
import Header from 'common/components/Header/Header';
import Sidebar from 'common/components/Sidebar/Sidebar';
import WorkInProgress from 'core/WorkInProgress/WorkInProgress';
import Spinner from 'common/components/Spinner/Spinner';

// HOOKS
import { useLocation } from 'react-router-dom';

// SECTION COMPONENTS
import Admin from 'core/Admin/Admin';
import ArticleListPage from 'core/Layout/ArticleListPage';
import ArticlePage from 'core/ArticlePage/ArticlePage';
import CommitteePage from 'core/Layout/CommitteePage';
import Directory from 'core/Directory/Directory';
import Events from 'core/Events/Events';
import Firm from 'core/Firm/Firm';
import ForgotPassword from 'core/ForgotPassword/ForgotPassword';
import Home from 'core/Home/Home';
import Login from 'core/Login/Login';
import PageList from 'core/Layout/PageList';
import PrivacyPolicy from 'core/PrivacyPolicy/PrivacyPolicy';
import PostForm from 'core/Social/PostForm';
import Register from 'core/Register/Register';
import ResetPassword from 'core/ResetPassword/ResetPassword';
import ResourceListPage from 'core/Layout/ResourceListPage';
import ResourcePage from 'core/Layout/ResourcePage';
import UserEdit from 'core/Users/UserEdit.jsx';
import UserProfile from 'core/Users/MemberDetailPage';

// GLOBAL STYLE
import './sass/app.scss';

const App = () => {
  const [state, dispatch] = useStore();

  const [open, setOpen] = useState(false);

  const token = getToken();

  const { hasAdminPermission, acceptedCookies, authCookies } = state;

  const location = useLocation();

  initAnalytics();

  // CHANGE FOLLOWING VARIABLE TO TRUE TO ENABLE MAINTENANCE STATE
  const isInMaintenance = process.env.CROWE_IS_IN_MAINTENANCE;

  const handleLoading = async () => {
    if (token) {
      const userId = getUserId();
      const currentUser = await getUserById(userId);
      dispatch({ type: 'SET_CURRENT_USER', payload: currentUser });

      const permissions = parseJwt(token).permissions;

      dispatch({
        type: 'SET_ADMIN_PERMISSION_TYPE',
        payload: hasPermission(permissions, ...ADMIN_PERMISSIONS),
      });
      dispatch({
        type: 'SET_FIRM_ADMIN_PERMISSION_TYPE',
        payload: hasPermission(permissions, ...FIRM_ADMIN_PERMISSIONS),
      });
    }

    const networkRoles = await getNetworkRoles();
    dispatch({
      type: 'SET_NETWORK_ROLES',
      // this sorting makes null appear last
      payload: networkRoles.sort(
        (a, b) =>
          (a.sort_order === null) - (b.sort_order === null) ||
          +(a.sort_order > b.sort_order) ||
          -(a.sort_order < b.sort_order)
      ),
    });
    const firmRoles = await getFirmRoles();
    dispatch({
      type: 'SET_FIRM_ROLES',
      payload: firmRoles.sort((a, b) => a.sort_order - b.sort_order),
    });
    const certifications = await getCertifications();
    dispatch({
      type: 'SET_CERTIFICATIONS',
      payload: certifications.sort((a, b) => a.name.localeCompare(b.name)),
    });
    const specialty_categories = await getSpecialtyCategories();
    dispatch({
      type: 'SET_SPECIALTY_CATEGORIES',
      payload: specialty_categories.sort((a, b) => a.sort_order - b.sort_order),
    });
    const specialties = await getSpecialties();
    dispatch({ type: 'SET_SPECIALTIES', payload: specialties });
    const industries = await getIndustries();
    dispatch({ type: 'SET_INDUSTRIES', payload: industries });
    const regions = await getRegions();
    dispatch({ type: 'SET_REGIONS', payload: regions });
    const cities = await getCities();
    dispatch({ type: 'SET_CITIES', payload: cities });
    const countries = await getCountries();
    dispatch({ type: 'SET_COUNTRIES', payload: countries });
    const firms = await getFirm();
    dispatch({ type: 'SET_FIRMS', payload: firms });
    const vlpServices = await getVLPServices();
    dispatch({ type: 'SET_VLP_SERVICES', payload: vlpServices });
  };

  useEffect(() => {
    handleLoading();
  }, [token]);

  const cookies = document.cookie;

  useEffect(() => {
    dispatch({ type: 'SET_AUTH_COOKIES', payload: document.cookie.includes('authToken') });
    dispatch({
      type: 'SET_ACCEPTED_COOKIES',
      payload: document.cookie.includes('acceptedCookies=true'),
    });
  }, [cookies]);

  return (
    <>
      {token && !hasAdminPermission && isInMaintenance ? (
        <WorkInProgress />
      ) : (
        <Suspense fallback={<Spinner />}>
          <div className="d-flex-col">
            {token && acceptedCookies && authCookies && (
              <Header isAuthenticated={token} open={open} setOpen={setOpen} />
            )}
            <div
              className={`root__container ${
                token && acceptedCookies && authCookies ? 'root__container--padding' : ''
              } app app-header-fixed ${open ? 'app-sidebar-mobile-toggled' : ''}`}
            >
              <div
                id="content"
                className={`${token && acceptedCookies && authCookies ? 'root__content' : ''}`}
              >
                {token && acceptedCookies && authCookies && (
                  <Sidebar
                    isAuthenticated={token}
                    open={open}
                    setOpen={setOpen}
                    pathname={location}
                  />
                )}

                {acceptedCookies !== null && authCookies !== null && (
                  <Suspense fallback={<Spinner />}>
                    <div className="root__section">
                      <Routes>
                        <Route path="/" element={<AuthRoute />}>
                          <Route exact path="/admin" element={<Admin />} />
                          <Route exact path="/articles/:slug" element={<ArticlePage />} />
                          <Route exact path="/directory/firms/:id" element={<Firm />} />
                          <Route exact path="/directory/members/:id" element={<UserProfile />} />

                          <Route path="/directory" element={<Directory />}>
                            <Route path="/directory/firms" element={<Directory />} />
                            <Route path="/directory/members" element={<Directory />} />
                            <Route path="/directory/desks" element={<Directory />} />
                          </Route>

                          <Route path="/events" element={<Events />}>
                            <Route path="/events/past" element={<Events />} />
                            <Route path="/events/upcoming" element={<Events />} />
                          </Route>

                          <Route exact path="/home" element={<Home />} />
                          <Route exact path="/social/new-post" element={<PostForm />} />

                          <Route path="/users" element={<UserEdit />}>
                            <Route exact path="/users/:id/edit" element={<UserEdit />} />
                          </Route>

                          <Route
                            exact
                            path="/:section/articles/:slug"
                            element={<ArticleListPage />}
                          />
                          <Route
                            exact
                            path="/:section/committees/:slug"
                            element={<CommitteePage />}
                          />
                          <Route exact path="/:section/pages/:slug" element={<PageList />} />
                          <Route
                            exact
                            path="/:section/pages/:slug/:subSlug"
                            element={<PageList />}
                          />
                          <Route
                            exact
                            path="/:section/resources/:slug"
                            element={<ResourceListPage />}
                          />
                          <Route exact path="/resource/:slug" element={<ResourcePage />} />
                          <Route exact path="/" element={<Navigate to="/home" />} />
                        </Route>
                        <Route exact path="/reset-password" element={<ResetPassword />} />
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/forgot-password" element={<ForgotPassword />} />
                        <Route exact path="/register" element={<Register />} />
                        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                        {<Route exact path="/" element={<Navigate to="/login" />} />}
                      </Routes>
                    </div>
                  </Suspense>
                )}
              </div>
            </div>
            {token && acceptedCookies && authCookies && <Footer />}
          </div>
        </Suspense>
      )}
    </>
  );
};

export default App;
